@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

/*ESTILOS HOME*/

.fondoPrincipal {
  background-image: url('./fondoPrinc.svg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.logoHome {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 170px;
}

.text-logoHome {
  font-family : Poppins;
  font-weight : bold;
  font-size : 48px;
  color : #FFFFFF;
  color : rgb(255, 255, 255);
  margin-left: 9px;
}

.textoHome-Mayu {
  font-family : Poppins;
  font-size : 38px;
  font-weight: 900;
  line-height : 41.56px;
  color : #FFFFFF;
  color : rgb(255, 255, 255);
  text-align: left;
}

.textoHome-Mayu::first-line {
  font-weight: 400;
}

.textoHome-Minu {
  font-family : Poppins;
  font-size : 14px;
  color : #FFFFFF;
  color : rgb(255, 255, 255);
  text-align: left;
}

.boton-contactanos{
  padding: 10px 17px;
  border-radius: 30px;
  border: none;
  background-color: #043c90;
  cursor: pointer;
}

.boton-contactanosCV{
  padding: 10px 17px;
  border-radius: 30px;
  border: none;
  background-color: #043c90;
  cursor: pointer;
  margin-top: 165px;
}

.alineacion-botonCV {
  text-align: center;
}

.alineacion-boton {
  text-align: left;
  margin-top: 60px;
}

.texto-contactanos{
  font-family : Poppins;
  font-size : 22px;
  color : #FFFFFF;
  color : rgb(255, 255, 255);
}

.logoAromas {
  width: 60px;
  height: 60px;
}

.imagenHome-container {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}

.textos-Home {
  margin-left: 22px;
}

.imagen-limpHome{
  width: 386px;
  height: 386px;
  margin-top: 60px;
}

@media screen and (max-width: 768px) {
  .fondoPrincipal {
    flex-direction: column;
    height: auto;
    text-align: center;
  }

  .textos-Home {
    margin-left: 0;
    margin-bottom: 50px;
  }

  .imagenHome-container {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 0;
    margin-right: 50px;
  }

  .boton-contactanosCV{
    margin-top: 55px;
    margin-bottom: 120px;
  }
  .imagen-limpHome {
    width: 300px;
    height: 300px;
    margin-top: 0;
  }

  .alineacion-boton {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .text-logoHome {
    font-size: 36px;
  }

  .textoHome-Mayu {
    font-size: 28px;
  }

  .textoHome-Minu {
    font-size: 12px;
  }

  .boton-contactanos {
    font-size: 16px;
    padding: 8px 15px;
  }

  .imagenHome-container {
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
  }
}

/*ESTILOS TEXTO EXPANDIBLE*/

.boton-redondo {
  width: 150px;
  height: 150px;
  background-color: #045cac;
  border-radius: 50%; /* Esto hace que el botón sea redondo */
  border: none;
  cursor: pointer;
}

.icon {
  width: 61px;
  height: 37px;
}

.icon2 {
  width: 61px;
  height: 37px;
  transform: rotate(180deg);
}

.expandText-btn {
  position: relative;
  text-align: center;
  top: -80px;
  z-index: 1;
}

.contenedor-expandible {
  width: 100%;
  height: 453px; 
  background-image: url('./fondo2.svg');
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  background-color:  #d4dfeb;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  top: -70px;
  z-index: -1;
  margin-bottom: -100px;
}

.text-expandible {
  font-family : Raleway;
  font-size : 23px;
  line-height : 29.88px;
  color : #052D3D;
  color : rgb(5, 45, 61);
  margin-left: 95px;
  font-weight: 500;
}

.imagenExpandible {
  width: 303px; /* Imagen ocupa todo el ancho de la carta */
  height: 303px; /* Altura automática según la proporción de la imagen */
}

.imagenLogoWSP {
  width: 274px;
  height: 62px;
}

.contenedor-logoWSP {
  position: fixed;
  top: 1000;
  left: 1000;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.btn-WSP {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .expandText-btn {
    position: relative;
    text-align: center;
    top: -100px;
    margin-bottom: -20px;
  }

  .contenedor-expandible {
    height: auto;
    top: -50px;
    z-index: -1;
    margin-bottom: 20px;
    background-size: cover;
    padding: 50px;
    width: 100%;
    position: relative;
  }

  .text-expandible {
    margin-top: 30px;
    margin-left: -100px;
    text-align: left;
    font-size: 19px;
  }

  .imagenExpandible {
    display: none;
  }

  .imagenLogoWSP {
    width: 200px;
    height: auto;
  }

  .contenedor-logoWSP {
    top: 70%;
    left: 74%;
  }
}

@media screen and (max-width: 576px) {
  .boton-redondo {
    width: 100px;
    height: 100px;
  }

  .expandText-btn {
    position: relative;
    text-align: center;
    top: -70px;
    margin-bottom: -60px;
    z-index: 1;
  }

  .contenedor-expandible {
    height: auto;
    top: -30px;
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
  }

  .icon,
  .icon2 {
    width: 31px;
    height: 19px;
  }

  .text-expandible {
    font-size: 14px;
    line-height: 24px;
    margin-left: -70px;
    margin-top: 20px;
    text-align: left;
  }

  .imagenExpandible {
    display: none;
  }

  .imagenLogoWSP {
    width: 150px;
    height: auto;
  }

  .contenedor-logoWSP {
    top: 85%;
    left: 60%;
  }
}


/**/
/*ESTILOS MARCAS*/

.cont-marcas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  margin: -50px 0;
  margin-bottom: 100px;
}

.cont-marcas1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  margin: -50px 0;
  margin-bottom: 60px;
}


.text-marcas {
  font-family : Poppins;
  font-weight: 500;
  font-size : 38px;
  line-height : 41.56px;
  color : #052D3D;
  color : rgb(5, 45, 61);
}

.img-logos {
  width: 1071px;
  height: 71px;
}

.img-logos2{
  width: 1071px;
}

@media screen and (max-width: 768px) {
  .cont-marcas {
    margin: 0;
    margin-bottom: 50px;
  }

  .cont-marcas1 {
    margin: 0;
    margin-bottom: 0px;
    margin-top: -100px;
  }

  .text-marcas {
    font-size: 32px;
    line-height: 32px;
  }

  .img-logos {
    width: 80%;
    max-width: 500px;
    height: auto;
  }

  .img-logos2 {
    width: 80%;
    max-width: 500px;
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .text-marcas {
    font-size: 26px;
    line-height: 26px;
  }

  .cont-marcas1 {
    margin: 0;
    margin-bottom: 0px;
    margin-top: 15px;
  }

  .img-logos {
    width: 90%;
    max-width: 400px;
    height: auto;
  }

  .img-logos2 {
    width: 90%;
    max-width: 400px;
    height: auto;
  }
}


/**/
/*ESTILOS CARRUSEL*/

.divSlider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  width: 100%; /* Cambiar el ancho del slider para adaptarse al contenedor */
  max-width: 1040px; /* Ajustar el ancho máximo si es necesario */
  height: 463px;
}

.carousel-item {
  margin: 0 10px; /* Establecer el margen entre las imágenes */
}

.card-img {
  width: 463px; /* Asegurarse de que la imagen no supere el tamaño del contenedor */
  height: 463px; /* Asegurarse de que la imagen no supere el tamaño del contenedor */
  border-radius: 5px;
}



/**/
/*ESTILOS TEXTO PRIMARIO*/

.fondo-text1 {
  background-image: url('./fondoRepetido.svg');
  background-size: cover;
  width: 100%;
  height: 575px;
  display: flex; /* Usa flexbox */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  margin-top: 90px;
  text-align: center;
}

.text-text1 {
  font-family : Raleway;
  font-size : 27px;
  line-height : 34.82px;
  color : #FFFFFF;
  color : rgb(255, 255, 255);
}

@media screen and (max-width: 768px) {
  .fondo-text1 {
    height: auto; /* Altura automática para adaptarse al contenido */
    margin-top: 50px; /* Reducir el margen superior */
    text-align: left;
  }

  .text-text1 {
    font-size: 20px; /* Reducir el tamaño del texto */
    line-height: 28px; /* Reducir el espacio entre líneas */
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 576px) {
  .text-text1 {
    font-size: 18px; /* Reducir aún más el tamaño del texto para dispositivos pequeños */
    line-height: 24px; /* Reducir aún más el espacio entre líneas */
  }
}


/**/
/*ESTILOS TEXTO VIDEO*/
.contenedor-videoytexto {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;/*62px;*/
  margin-bottom: 30px;
}

.contenedor-video {
  width: 1042px;
  height: 436px;
}

.text-textvideo {
  font-family: Raleway;
  font-size: 27px;
  font-weight: 500;
  line-height: 34.82px;
  color: #052D3D;
  color: rgb(5, 45, 61);
  margin-top: 49px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contenedor-video {
    width: 80%; /* Reducir el ancho del video para dispositivos más pequeños */
    height: auto; /* Ajustar la altura automáticamente para mantener la proporción */
  }

  .text-textvideo {
    font-size: 20px; /* Reducir el tamaño del texto */
    line-height: 28px; /* Reducir el espacio entre líneas */
    margin-top: 20px; /* Reducir el margen superior */
  }
}

@media screen and (max-width: 576px) {
  .contenedor-video {
    width: 90%; /* Reducir aún más el ancho del video para dispositivos muy pequeños */
    height: auto;
  }

  .text-textvideo {
    font-size: 18px; /* Reducir aún más el tamaño del texto para dispositivos pequeños */
    line-height: 24px; /* Reducir aún más el espacio entre líneas */
    margin-top: 10px; /* Reducir aún más el margen superior */
  }
}


/**/
/*ESTILOS MODALES*/

.img-modals{
  width: 290px;
  height: 290px;
}

.img-modalsBANK{
  width: 290px;
  height: 290px;
}

.img-modalsEDIF{
  width: 290px;
  height: 290px;
}

.img-modalsEMP{
  width: 290px;
  height: 290px;
}

.text-textModales {
  margin-top: 35px;
  text-align: center;
  font-family : Poppins;
  font-weight : bold;
  font-size : 18px;
  color : #303030;
  color : rgb(48, 48, 48);
}

.modales {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  grid-row-gap: 15px;
}

@media screen and (max-width: 768px) {
  .modales {
    grid-template-columns: repeat(2, 1fr);
    width: 80%; 
  }

  .img-modals{
    width: 200px; 
    height: 200px;
  }

  .img-modalsBANK{
    width: 200px;
    height: 200px;
  }

  .img-modalsEDIF{
    width: 200px;
    height: 200px;
  }

  .img-modalsEMP{
    width: 200px;
    height: 200px;
  }

  .img-modalsCENTER{
    width: 200px; 
    height: 200px;
    margin-left: 14px;
  }

  .text-textModales {
    font-size: 16px; 
    margin-top: 25px;
    text-align: center;
  }

}

@media screen and (max-width: 576px) {
  .modales {
    grid-template-columns: 1fr; 
    width: 90%;
  }

  .img-modals {
    width: 150px;
    height: 150px;
    margin: auto;
  }

  .img-modalsBANK{
    width: 150px;
    height: 150px;
    margin-left: 7px;
  }

  .img-modalsEDIF{
    width: 150px;
    height: 150px;
    margin-left: 27px;
  }

  .img-modalsEMP{
    width: 150px;
    height: 150px;
    margin-left: 3px;
  }

  .img-modalsCENTER{
    width: 150px;
    height: 150px;
    margin-left: 25px;
  }

  .text-textModales {
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
  }
}

/**/
/*ESTILOS TEXTO FONDO REPETIDO*/

.imagenList1 {
  margin-top: 40px;
  width: 100%;
  height: auto;
  margin-bottom: -5px;
}

.imagenList2 {
  width: 100%;
  height: auto;
}

.fondo-text2FONDO {
  width: 100%;
  width: 100%;
  display: flex; 
  justify-content: center;
  align-items: baseline;
  margin-top: 50px;
}

@media screen and (max-width: 576px) {
  .responsive-image {
      height: auto;
  }
}

/**/
/*ESTILO TEXTO FORMULARIO*/

.textFormulario {
  text-align: center;
  margin: 60px 0;
  font-family: Poppins;
  font-size: 34px;
  color: #000000;
  color: rgb(0, 0, 0);
}

.textFormulario .firstLine { 
  font-weight: 800; 
}

@media screen and (max-width: 768px) {
  .textFormulario {
      font-size: 28px; /* Reducir el tamaño del texto en dispositivos medianos */
      margin: 40px 0; /* Reducir el margen en dispositivos medianos */
      padding: 50px;
  }
}

@media screen and (max-width: 576px) {
  .textFormulario {
      font-size: 24px; /* Reducir aún más el tamaño del texto en dispositivos pequeños */
      margin: 30px 0; /* Reducir aún más el margen en dispositivos pequeños */
  }
}


/**/
/*ESTILOS FORMULARIO PRINCIPAL*/

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.form-container {
  width: 868px; /* Ancho ajustado para contener los elementos */
  height: 449px;
  padding: 20px;
  background-color: #CDD9E7;
}

.titulo-form-modal1 {
  font-family: Poppins;
  font-weight: bold;
  font-size: 25px;
  color: #303030;
  text-align: center;
}

.divsForm {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.divsFormObs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-input1,
.form-input2,
.form-input3,
.form-input4 {
  width: 374px; /* Ancho del 50% con espacio entre los elementos */
  height: 48px;
  padding: 20px;
  padding-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-left: 10px;
}

.form-inputobs {
  width: 767px; /* Ancho completo */
  height: 129px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.divFormBtn {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 22px;
}

.form-container input::placeholder {
  font-family : Poppins;
  font-size : 21px;
  color : #303030;
  color : rgb(48, 48, 48);
}

.form-button {
  width: 215px;
  height: 48px;
  padding: 10px;
  background-color: #1F5387;
  color: #fff;
  border: none;
  cursor: pointer;
}


.form-error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

.form-success {
  color: green;
  font-size: 0.8em;
  margin-top: 5px;
}

.texto-BtnForm {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}

@media only screen and (max-width: 768px) {
  .form-container {
    width: 100%; /* Ancho completo */
    height: auto; /* Altura automática */
    padding: 10px; /* Ajuste de espacio interno */
  }

  .footer-content {
    padding: 30px;
  }
  
  .titulo-form-modal1 {
    font-size: 20px; /* Reducción de tamaño de fuente */
  }
  
  .divsForm,
  .divsFormObs {
    flex-direction: column; /* Cambio a disposición de columna */
  }
  
  .form-input1,
  .form-input2,
  .form-input3,
  .form-input4,
  .form-inputobs {
    width: 100%; /* Ancho completo */
    margin: 10px 0; /* Ajuste de margen */
  }
  
  .divFormBtn {
    justify-content: center; /* Centrar botón */
    margin: 10px 0; /* Ajuste de margen */
  }
}

/**/
/*ESTILOS MAPA*/

.cont-mapa {
  background-color: #CDD9E7;
  width: 100%;
  height: 523px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapapa {
  width: 1041px;
  height: 444px;
}

@media only screen and (max-width: 768px) {
  .mapapa {
    width: 100%;
    height: 450px;
  }
}

@media only screen and (max-width: 576px) {
  .mapapa {
    height: 400px;
  }
}


/**/
/*ESTILOS FOOTER CONTACTO*/

.footerDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
}

.logoContacto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imagenContacto {
  width: 104px;
  height: 103px;
}

.footerLogoTexto {
  font-family : Poppins;
  font-weight : bold;
  font-size : 30px;
  color : #0A93D2;
  color : rgb(10, 147, 210);
  margin-top: 0;
}

.footerLogoTextoH2 {
  font-family : Poppins;
  font-weight : bold;
  font-size : 18px;
  color : #052D3D;
  color : rgb(5, 45, 61);
  text-align: left;
  margin-top: 0;
}

.footerTextContacto {
  font-family : Poppins;
  font-size : 19px;
  color : #000000;
  color : rgb(0, 0, 0);
  text-align: left;
  line-height: 1.2;
}

.contacto-textos {
  margin-left: 38px;
}

@media only screen and (max-width: 576px) {
  .footerDiv {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .contacto-textos {
    margin: 20px 0;
    text-align: center;
  }
}

.nonone {
  display: none;
}

.contenedor-full, .overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5); /* Color del overlay con transparencia */
  z-index: 1; /* Asegura que el overlay esté por encima de otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contenedor-fullCV, .overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5); /* Color del overlay con transparencia */
  z-index: 9999; /* Asegura que el overlay esté por encima de otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-modal {
  width: 624px;
  height: 745px;
  background-color: #EFEFEF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

.overlay {
  background-color: rgba(49,49,49,0.8);
}

.form-containerMODAL input::placeholder {
  font-family : Poppins;
  font-size : 15px;
  color : #303030;
  color : rgb(48, 48, 48);
}

.modal-imageMODAL {
  width: 549px;
  height: 219px;
  margin-top: 0px;
}

.modal-imageMODALHOSP {
  width: 549px;
  height: 219px;
}

.modal-tituloHOSP {
  font-family : Poppins;
  font-weight : bold;
  font-size : 26px;
  color : #303030;
  color : rgb(48, 48, 48);
  margin-top: 5px;
}

.parrafo-modalHOSP {
  font-family: Myriad Pro;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: -20px;
  line-height: 1.2;
}

.footer-contentMODALHOSP {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 0px;
}

.modal-imageMODALCOLE {
  width: 549px;
  height: 219px;
}

.modal-tituloCOLE {
  font-family : Poppins;
  font-weight : bold;
  font-size : 26px;
  color : #303030;
  color : rgb(48, 48, 48);
  margin-top: 5px;
}

.parrafo-modalCOLE {
  font-family: Myriad Pro;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: -20px;
  line-height: 1.2;
}

.footer-contentMODALCOLE {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 0px;
}

.modal-imageMODALBANK {
  width: 549px;
  height: 219px;
}

.modal-tituloBANK {
  font-family : Poppins;
  font-weight : bold;
  font-size : 26px;
  color : #303030;
  color : rgb(48, 48, 48);
  margin-top: 5px;
}

.parrafo-modalBANK {
  font-family: Myriad Pro;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: -20px;
  line-height: 1.2;
}

.footer-contentMODALBANK {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 0px;
}

.modal-imageMODALSEGU {
  width: 549px;
  height: 219px;
}

.modal-tituloSEGU {
  font-family : Poppins;
  font-weight : bold;
  font-size : 26px;
  color : #303030;
  color : rgb(48, 48, 48);
  margin-top: 5px;
}

.parrafo-modalSEGU {
  font-family: Myriad Pro;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: -20px;
  line-height: 1.2;
}

.footer-contentMODALSEGU {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 0px;
}

.modal-imageMODALCV {
  width: 549px;
  height: 219px;
  border-radius: 10px;
}

.modal-tituloCV {
  font-family : Poppins;
  font-weight : bold;
  font-size : 26px;
  color : #303030;
  color : rgb(48, 48, 48);
  margin-top: 5px;
}

.parrafo-modalCV {
  font-family: Myriad Pro;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: -20px;
  line-height: 1.2;
}

.footer-contentMODALCV {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 0px;
}

.modal-imageMODALEMP {
  width: 549px;
  height: 219px;
  margin-top: -70px;
}

.footer-contentMODAL {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-contentMODALEMP {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: -60px;
}

.form-containerMODAL {
  width: 466px; /* Ancho ajustado para contener los elementos */
  height: 241px;
  padding: 5px;
  background-color: #CDD9E7;
}

.titulo-form-modal1MODAL {
  font-family: Poppins;
  font-weight: bold;
  font-size: 15px;
  color: #303030;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal-titulo {
  font-family : Poppins;
  font-weight : bold;
  font-size : 26px;
  color : #303030;
  color : rgb(48, 48, 48);
  margin-top: 5px;
}

.modal-tituloEMP {
  font-family : Poppins;
  font-weight : bold;
  font-size : 26px;
  color : #303030;
  color : rgb(48, 48, 48);
  margin-top: 5px;
}

.parrafo-modal {
  font-family: Myriad Pro;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: -20px;
  line-height: 1.2;
}

.parrafo-modalEMP {
  font-family: Myriad Pro;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: -10px;
  line-height: 1.2;
}

.divsFormMODAL {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: center;
}

.divsFormObsMODAL {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-input1MODAL,
.form-input2MODAL,
.form-input3MODAL,
.form-input4MODAL {
  width: 201px; /* Ancho del 50% con espacio entre los elementos */
  height: 26px;
  padding: 20px;
  padding-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 5px;
  margin-left: 5px;
}

.form-inputobsMODAL {
  width: 412px; /* Ancho completo */
  height: 69px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.divFormBtnMODAL {
  display: flex;
  justify-content: flex-end;
  margin-right: 26px;
  margin-top: 5px;
}

.form-buttonMODAL {
  width: 115px;
  height: 25px;
  background-color: #1F5387;
  color: #fff;
  border: none;
  cursor: pointer;
}


.form-errorMODAL {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

.form-successMODAL {
  color: green;
  font-size: 0.8em;
  margin-top: 5px;
}

.texto-BtnFormMODAL {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 700;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}

.close-button {
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 10px;
}

.close-button::before,
.close-button::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: black;
  top: 0;
  left: 50%;
  transform-origin: center;
}

.close-button::before {
  transform: rotate(45deg);
}

.close-button::after {
  transform: rotate(-45deg);
}

/**/
